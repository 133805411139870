import './PlayerHand.css';

import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import Card from "../../../card/Card";
import {useActions} from "../../../../hooks/useActions";
import {CanBePlayedCard} from "../../../../services/CanBePlayedCard";
import {useDrag} from "react-dnd";


function PlayerHandCard({card, handKey}) {

    const {phase} = useTypedSelector(state => state.game);
    const {tutorial} = useTypedSelector(state => state.auth);
    const {playerFire, playerPow, playerDiamond, playerHand, playerTable, cpuTable} = useTypedSelector(state => state.game);
    const {playCard, setPlayedCard, setPhase, endTurn, setPlayerHand, setTutorial} = useActions();

    let targetClasses = '';
    let canBePlayed = false;

    if (phase === 'play_card') {
        canBePlayed = CanBePlayedCard(card, playerFire, playerPow, playerDiamond, playerTable, cpuTable);

        if (canBePlayed) {
            targetClasses += ' animateCardBoxShadow';
        }
    }

    if(phase === 'discard_card'){
        targetClasses += ' animateCardBoxShadowDiscard';
    }

    const checkTargetWhenPlayCard = function(){
        if(card.type_card === 'unit'){
            setPlayedCard({card:card, handKey:handKey, target:null});
            setPhase('select_unit_target');
        }

        if(card.type_card === 'spell'){
           if(card.ability.need_select_target === 1){
               setPlayedCard({card:card, handKey:handKey, target:null});
               setPhase('select_spell_target');

           }else{
               setPhase('play_card_progress');
               playCard({card:null, handKey:handKey, target:null});
           }
        }

        delete playerHand[handKey];
        setPlayerHand(playerHand);

        // Tutorial
        if(tutorial.step === 'PlaySecondCard50'){
            tutorial.step = 'PutUnitOnTable52';
            setTutorial(tutorial);
        }
        if(tutorial.step === 'PlaySpellCard70'){
            tutorial.step = 'SpellTarget75';
            setTutorial(tutorial);
        }
        //
    }

    return (
        <div className='cardWrapper' onClick={() => {
            if (canBePlayed) {
                checkTargetWhenPlayCard();
            }
            if(phase === 'discard_card'){
                endTurn(handKey);
            }
        }}>
            <Card card={card} targetClasses={targetClasses}/>
        </div>
    );
}

export default PlayerHandCard;