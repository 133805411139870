import './Cell.css';
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import Card from "../../card/Card";
import AD from "../player/table/AD";
import TargetPlayUnit from "./TargetPlayUnit";
import TargetPlaySpell from "./TargetPlaySpell";
import MiddlewareAbility from "./MiddlewareAbility";
import Sleep from "./Sleep";


function Cell({tableKey, table, side}) {

    const {showUnitAttack} = useTypedSelector(state => state.game);

    let rootClasses = 'cell';
    if (showUnitAttack.tableKey !== null && showUnitAttack.tableKey === tableKey && showUnitAttack.side === side) {
        rootClasses += ' animate__animated';

        if (side === 'player') {
            rootClasses += ' animate_slideOutUp_Custom';
        } else {
            rootClasses += ' animate_slideOutDown_Custom';
        }
    }
    let wall = false;

    if (table[tableKey] !== 0 && table[tableKey] !== null && table[tableKey].card.ability !== null && table[tableKey].card.ability.type_effect === 'wall') {
        wall = true;
    }

    return (
        <div className={rootClasses}>

            <MiddlewareAbility tableKey={tableKey} side={side} cell={table[tableKey]}/>
            <TargetPlayUnit tableKey={tableKey} table={table} side={side}/>
            <TargetPlaySpell tableKey={tableKey} table={table} side={side}/>

            {(wall === false && side === 'player' && table[tableKey] !== 0 && table[tableKey] !== null && table[tableKey].sleep === false) ?
                <AD tableKey={tableKey}/> : <div></div>
            }

            {(wall === false && table[tableKey] !== 0 && table[tableKey] !== null && table[tableKey].sleep === true) ?
                <Sleep/> : <div></div>
            }

            {(table[tableKey] === 0 || table[tableKey] === null) ?
                <div></div>
                :
                <Card card={table[tableKey].card} battle={true}/>

            }

        </div>
    );
}

export default Cell;