

export const PromoStory = {
    level: 7,
    name: 'Troll Queen (Coming soon)',
    cpu1Name: '',
    cpu2Name: '',
    cpu3Name: '',
    text: 'COMING SOON!',
    cpu1: {
        id: 0,
        before: {
            type: 'comics',
            comicsSetting:{
                path: '',
                slideNumber: 0
            }
        },
        after: {
            player: {
                type: 'text',
                step: {
                    0: '',
                }
            },
            cpu: {
                type: 'text',
                step: {
                    0: '',
                }
            }
        }
    },
    cpu2: {
        id: 0,
        before: {
            type: 'text',
            step: {
                0: '',
            }
        },

        after: {
            player: {
                type: 'text',
                step: {
                    0: '',
                }
            },
            cpu: {
                type: 'text',
                step: {
                    0: '',
                }
            }
        },
    },
    cpu3: {
        id:0,
        before: {
            type: 'text',
            step: {
                0: '',
            }
        },
        after: {
            player: {
                type: 'comics',
                comicsSetting:{
                    path: '',
                    slideNumber: 0
                }
            },
            cpu: {
                type: 'comics',
                comicsSetting:{
                    path: '/',
                    slideNumber: 0
                }
            }
        },
    }
}