import React from 'react';
import {Button} from "antd";
import {useActions} from "../../../hooks/useActions";
import {useNavigate} from "react-router-dom";
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { MusicService } from '../../../services/MusicService';


function TextStoryComponent({cpuStory, endGame, setBossWindowVisible, tutorialAction}) {

    const {setCpuStory, startGame, setTutorial} = useActions();
    const mapCpuIco = 'map-cpu-ico-' + cpuStory.story.name;
    const {tutorial, isLoading} = useTypedSelector(state => state.auth);
    let navigate = useNavigate();

    let text = '';
    if (cpuStory.action === 'before') {
        text = cpuStory.story[cpuStory.cpuNumber][cpuStory.action].step[0];
    } else {
        text = cpuStory.story[cpuStory.cpuNumber][cpuStory.action][endGame.winner].step[0];
    }

    return (<div>
            <div className={'map-cpu-ico ' + mapCpuIco.replace(/ /g,"-")}></div>
            <div className={'map-small-cpu-container'}>
            <div className="map-cpu-text">{text}</div>

            <div className={'map-battle-button'}>

                {(cpuStory.action === 'before') ? (
                    <Button size="large" type="primary" loading={isLoading} onClick={async () => {
                        tutorialAction();
                        await startGame(cpuStory.cpuId);
                        navigate('/game');

                        MusicService.stopTutorial();
                        MusicService.stopMenu();
                        MusicService.playBattleMusic();
                    }}>
                        Battle
                    </Button>
                ) : (
                    <Button size="large" type="primary" onClick={() => {

                        cpuStory.action = null;
                        setCpuStory(cpuStory);
                        setBossWindowVisible(true);
                        
                        if(tutorial.isActive === false && tutorial.step === 'BattleFinish80'){
                            tutorial.isActive = true;
                            tutorial.step = 'MenuResearch90';
                            setTutorial(tutorial);
                        }

                    }}>
                        Ok
                    </Button>
                )}
            </div>

        </div>
    </div>);

}

export default TextStoryComponent;