import { sounds } from "./sound"

export const SoundsMiddlewareMap = { 
  1041: function(){ return  sounds.zombie() }, //Gabby
  1049: function(){ return sounds.spark_summon() }, //fire e
  1059: function(){ return sounds.healing() }, // earth e
  1053: function(){ return sounds.shock_damage() }, // air e

  1048: function(){ return sounds.dark_damage() }, // Zombie W
  1038: function(){ return sounds.healing() }, // Cat girl
  1080: function(){ return sounds.battle_play_air_mana() }, // Air Witch
  1081: function(){ return sounds.battle_play_earth_mana() }, // Earth Witch
  1082: function(){ return sounds.battle_play_fire_mana() }, // Fire Witch
  1084: function(){ return sounds.healing_potion() }, // Alice, Demon Hunter
  1085: function(){ return sounds.dark_damage() }, // Demon Princess
}
