import {useTypedSelector} from "../../../hooks/useTypedSelector";
import Box from "./Box";
import BoxOpenWindow from "./BoxOpenWindow";
import React from "react";
import TutorialComponent from "../../tutorial/TutorialComponent";
import CardVeryBig from "../../card/CardVeryBig";
import NutakuBox from "./NutakuBox";

function ShopComponent() {

    const {boxes, nutakuBoxes} = useTypedSelector(state => state.auth);

    const {openBox, isLoading} = useTypedSelector(state => state.auth);

    let classContainer = 'shopOpenBox';
    if (openBox !== false) {
        classContainer += ' shopOpenBoxActive';
    }
    let loadingClass = 'shopLoading';
    if(isLoading){
        loadingClass += ' shopLoadingVisible';
    }

    return (
        <div className='shopContainer'>
            <div className={loadingClass}>
                <div id='l3'></div>
            </div>
            <TutorialComponent/>
            <CardVeryBig/>
            <div className="shopBoxLine">

                <Box box={boxes[0]}/>
                <Box box={boxes[1]}/>
                <Box box={boxes[2]}/>

            </div>

            <div className="shopNutakuBoxLine">
                <NutakuBox box={nutakuBoxes[0]}/>
                <NutakuBox box={nutakuBoxes[1]}/>
                <NutakuBox box={nutakuBoxes[2]}/>
            </div>

            <div className={classContainer}>
                {(openBox !== false) ?
                    <BoxOpenWindow openBox={openBox}/>
                    :
                    <div></div>
                }

            </div>
        </div>
    );
}

export default ShopComponent;