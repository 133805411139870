export const TowerStory = {
    level: 5,
    name: 'Tower',
    cpu1Name: 'First Wall',
    cpu2Name: 'Second Wall',
    cpu3Name: 'Third Wall',
    text: 'In the northeast, amidst the desert, stands a solitary tower. Who built it and why is unknown. But the builder made great efforts to ensure that no one could enter this tower, and no one could leave. If you were to approach closer, it would seem as though a living being is trapped in this tower, in need of help.',
    cpu1: {
        id: 15,
        before: {
            type: 'comics',
            comicsSetting: {
                path: '/img/stories/tower/intro/',
                slideNumber: 6
            }
        },
        after: {
            player: {
                type: 'text',
                step: {
                    0: "Good job, only two walls left.",
                }
            },
            cpu: {
                type: 'text',
                step: {
                    0: 'Use more powerful spells; you can\'t take down these walls with simple magic.',
                }
            }
        }
    },
    cpu2: {
        id: 16,
        before: {
            type: 'text',
            step: {
                0: 'The wicked hag Alice made the second wall from earth magic. Be careful, the earth golem can heal itself.',
            }
        },

        after: {
            player: {
                type: 'text',
                step: {
                    0: "You're the best! One more wall and I'll be free.",
                }
            },
            cpu: {
                type: 'text',
                step: {
                    0: 'You need to find stronger allies.',
                }
            }
        }
    },
    cpu3: {
        id: 17,
        before: {
            type: 'text',
            step: {
                0: 'The last wall is very dangerous. To get through it, you need to show wisdom and agility.',
            }
        },
        after: {
            cpu: {
                type: 'text',
                step: {
                    0: 'Use more healing, both from potions and from allies like Cat Girl who can heal you.',
                }
            },
            player: {
                type: 'comics',
                comicsSetting: {
                    path: '/img/stories/tower/win/',
                    slideNumber: 15
                }
            }
        },

    },
}
