import {sounds} from "../sounds/sound";
import {store} from "../store";


export const MusicService = {

    tutorialMusic: null as any,

    battleMusic: null as any,

    menuMusic: null as any,

    afterBattle: null as any,

    playBattleWin: function (sound: any) {

        if(MusicService.afterBattle === null) {
            MusicService.afterBattle = sound;
            MusicService.afterBattle.play();
        }
    },
    stopBattleWin: function () {

        if (MusicService.afterBattle !== null) {
            MusicService.afterBattle.stop();
        }
        MusicService.afterBattle = null;
    },

    playTutorial: function () {

        /*  MusicService.tutorialMusic = new Howl({
               src: sounds.getTutorialRandomMusic(),
               volume: 0.3,
               onend: function(){
                  if(MusicService.tutorialMusic !== null){
                      MusicService.playTutorial();
                  }
               }
              });

              MusicService.tutorialMusic.play();     */
    },

    stopTutorial: function () {
        if (MusicService.tutorialMusic !== null) {
            MusicService.tutorialMusic.stop();
        }
        MusicService.tutorialMusic = null;
    },

    playBattleMusic: function () {
        let state = store.getState();
        if (state.auth.music.playBattle === false) {
            return;
        }

        MusicService.battleMusic = new Howl({
            src: sounds.getBattleRandomMusic(),
            volume: 0.2,
            onend: function () {
                if (MusicService.battleMusic !== null) {
                    MusicService.playBattleMusic();
                }
            }
        });

        MusicService.battleMusic.play();
    },

    stopBattle: function () {
        if (MusicService.battleMusic !== null) {
            MusicService.battleMusic.stop();
        }

        MusicService.battleMusic = null;
    },

    playMenuMusic: function () {
        let state = store.getState();
        if (state.auth.music.playMenu === false) {
            return;
        }
        MusicService.menuMusic = new Howl({
            src: sounds.getMenuRandomMusic(),
            volume: 0.2,
            onend: function () {
                if (MusicService.menuMusic !== null) {
                    MusicService.playMenuMusic();
                }
            }
        });

        MusicService.menuMusic.play();
    },

    stopMenu: function () {
        if (MusicService.menuMusic !== null) {
            MusicService.menuMusic.stop();
        }

        MusicService.menuMusic = null;
    },

}