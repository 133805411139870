export const TowerStoryPart2 = {
    level: 6,
    name: 'Demon Hunter',
    cpu1Name: 'Fire Witch',
    cpu2Name: 'Earth Witch',
    cpu3Name: 'Demon Princess',
    text: 'Something went wrong with this princess in the towers',
    cpu1: {
        id: 18,
        before: {
            type: 'comics',
            comicsSetting: {
                path: '/img/stories/tower_escape/intro/',
                slideNumber: 22
            }
        },
        after: {
            player: {
                type: 'text',
                step: {
                    0: "Good job!",
                }
            },
            cpu: {
                type: 'text',
                step: {
                    0: 'Hm... Maybe you need more powerful spells.',
                }
            }
        }
    },
    cpu2: {
        id: 19,
        before: {
            type: 'text',
            step: {
                0: 'Destroy this witch and take me time to prepare for final battle.',
            }
        },

        after: {
            player: {
                type: 'text',
                step: {
                    0: "Well done! You are really strong man!",
                }
            },
            cpu: {
                type: 'text',
                step: {
                    0: 'Earth... Earth... To beat them you need stronger units.',
                }
            }
        }
    },
    cpu3: {
        id: 20,
        before: {
            type: 'text',
            step: {
                0: 'I am ready to fight with demon. Lets smashed this creature together! ',
            }
        },
        after: {
            cpu: {
                type: 'text',
                step: {
                    0: 'Oh Goodness, demon stronger than I thought. Try to weaken the demon with curse spell.',
                }
            },
            player: {
                type: 'comics',
                comicsSetting: {
                    path: '/img/stories/tower_escape/win/',
                    slideNumber: 13
                }
            }
        },

    },
}
