import './Shop.css';
import {NutakuPaymentService} from "../../../services/NutakuPaymentService";
import {useEffect} from "react";
import UserService from "../../../api/UserService";
import {useActions} from "../../../hooks/useActions";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
// @ts-ignore
import gemImage from "../../../images/main_menu/gem.png"

function NutakuBox(box: any) {
    const {updateUser, setIsLoading} = useActions();
    const {isLoading} = useTypedSelector(state => state.auth);

    useEffect(() => {
        const onMessage = async (event: any) => {

            if (event.data.type === "payment_callback") {
                if (event.data.order.responseCode == 'ok') {
                    await UserService.nutakuPaymentImplement(event.data.order.paymentId);
                    await updateUser();
                }

                setIsLoading(false);
            }
        };

        window.addEventListener('message', onMessage);

        return () => {
            window.removeEventListener('message', onMessage);
        }
    }, []);

    return (
        <div className={"shopBoxContainerNutaku nutakuBox-" + box.box.id}>
            <div className='shopGemsRewad'>{box.box.reward_gems}<img className="NutakuBoxGem" src={gemImage}/></div>

            <div className='shopNutakuFooter'>
                <div className='shopNutakuPrice'>{box.box.price}</div>
                <div className='shopNutakuCoin'></div>

                <div className='shopButtonOpen shopButtonOpenNutaku' onClick={() => {
                    if (isLoading) {
                        return;
                    }
                    setIsLoading(true);

                    NutakuPaymentService.start(box.box);
                }}></div>

            </div>

        </div>
    );
};


export default NutakuBox;