import './Navbar.css';
// @ts-ignore
import gemImage from "../../images/main_menu/gem.png"


import {useTypedSelector} from "../../hooks/useTypedSelector";
import {useNavigate} from "react-router-dom";
import {useActions} from '../../hooks/useActions';
import EA from './EA';
import MusicButton from './MusicButton';

function Navbar() {
    const {user, tutorial} = useTypedSelector(state => state.auth);
    const {setTutorial} = useActions();
    let navigate = useNavigate();

    // @ts-ignore
    return (
        <div className='main_menu'>
            <div className="main_menu_buttons">
                <div className="m_n_button" onClick={() => {
                    navigate('/');
                    // Tutorial
                    if (tutorial.isActive === true && tutorial.step === 'ClickHome140') {
                        tutorial.step = 'Final150';

                        setTutorial(tutorial);
                    }
                    // end
                }}>Home
                </div>
                <div className="m_n_button" onClick={() => {
                    navigate('/gallery');
                }}>Gallery
                </div>
                <div className="m_n_button" onClick={() => {
                    navigate('/deck');
                    // Tutorial
                    if (tutorial.isActive === true && tutorial.step === 'ClickDeck110') {
                        tutorial.step = 'ClickAdd130';
                        setTutorial(tutorial);
                    }
                    // end
                }}>Deck
                </div>
                <div className="m_n_button" onClick={() => {
                    navigate('/shop');
                    // Tutorial
                    if (tutorial.isActive === true && tutorial.step === 'MenuResearch90') {
                        tutorial.step = 'ClickShop100';

                        setTutorial(tutorial);
                    }
                    // end

                }}>Shop
                </div>
            </div>

            <div className="main_menu_stats">
                <div className="main_menu_level" title="Level"><span
                >Level: {user.level}</span></div>
                <div className="main_menu_silver" title="Gems">
                    <img className="stat_image" src={gemImage}/>
                    <span>{user.silver}</span>
                </div>
                <MusicButton/>
            </div>


        </div>
    );
}

export default Navbar;