import UserService from "../api/UserService";

export const NutakuPaymentService = {

    start: async function (box: any) {


        let result = await UserService.nutakuPaymentCreate(box.id);

        let message = {
            type: 'payment',
            order: {
                order_id: result.data.id,
                id: box.id,
                price: box.price,
                count: 1,
                description: box.description,
                name: box.name,
                image_url: 'https://nutaku.depraved.world/img/nutaku/' + box.id + '.png'
            }
        };

        window.parent.postMessage(message, '*');
    }
}