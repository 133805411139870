import {Howl} from 'howler';

export const sounds = {

    battle_play_air_mana: function () {
        return new Howl({src: [require('./src/battle_play_air_mana/battle_play_air_mana_1.mp3')]})
    },
    battle_play_fire_mana: function () {
        return new Howl({src: [require('./src/battle_play_fire_mana/battle_play_fire_mana_1.mp3')]})
    },
    battle_play_earth_mana: function () {
        return new Howl({src: [require('./src/battle_play_earth_mana/battle_play_earth_mana_1.mp3')]})
    },

    battle_play_spell: function () {
        return new Howl({src: [require('./src/battle_play_spell/battle_play_spell_1.mp3')]})
    },
    battle_play_unit: function () {
        return new Howl({src: [require('./src/battle_play_unit/battle_play_unit_1.mp3')]})
    },
    battle_unit_dead: function () {
        return new Howl({src: [require('./src/battle_unit_dead/battle_unit_dead_1.mp3')]})
    },

    battle_end_turn: function () {
        return new Howl({src: [require('./src/battle_end_turn/battle_end_turn_1.mp3')]})
    },
    battle_start_turn: function () {
        return new Howl({src: [require('./src/battle_start_turn/battle_start_turn_1.mp3')]})
    },


    battle_unit_set_attack: function () {
        return new Howl({src: [require('./src/battle_unit_set_attack/battle_unit_set_attack_1.mp3')]})
    },
    battle_unit_set_defence: function () {
        return new Howl({src: [require('./src/battle_unit_set_defence/battle_unit_set_defence_1.mp3')]})
    },

    battle_unit_fight: function () {
        return new Howl({src: [require('./src/battle_unit_fight/battle_unit_fight_1.mp3')]})
    },
    battle_unit_deal_enemy_damage: function () {
        return new Howl({src: [require('./src/battle_unit_deal_enemy_damage/battle_unit_deal_enemy_damage_1.mp3')]})
    },

    battle_win: function () {
        return new Howl({src: [require('./src/battle_win/battle_win_1.mp3')]})
    },

    battle_lose: function () {
        return new Howl({src: [require('./src/battle_lose/battle_lose_1.mp3')]})
    },


    zombie: function () {
        return new Howl({src: sounds.getZombieRandomSound()})
    },

    port_rats: function () {
        return new Howl({src: sounds.getPortRatsRandomSound()})
    },


    //cards
    acid_spores: function () {
        return new Howl({src: [require('./src/cards/cards_acid_spores/cards_acid_spores_1.mp3')]})
    },
    air_elemental: function () {
        return new Howl({src: [require('./src/cards/cards_air_elemental/cards_air_elemental_1.mp3')]})
    },
    blast: function () {
        return new Howl({src: [require('./src/cards/cards_blast/cards_blast_1.mp3')]})
    },
    blessing: function () {
        return new Howl({src: [require('./src/cards/cards_blessing/cards_blessing_1.mp3')]})
    },
    cat_girl: function () {
        return new Howl({src: [require('./src/cards/cards_cat_girl/cards_cat_girl_1.mp3')]})
    },
    chain_lightning: function () {
        return new Howl({src: [require('./src/cards/cards_chain_lightning/cards_chain_lightning_1.mp3')]})
    },
    cloud: function () {
        return new Howl({src: [require('./src/cards/cards_cloud/cards_cloud_1.mp3')]})
    },
    desperate_passion: function () {
        return new Howl({src: [require('./src/cards/cards_desperate_passion/cards_desperate_passion_1.mp3')]})
    },
    dickfither: function () {
        return new Howl({src: [require('./src/cards/cards_dickfither/cards_dickfither_1.mp3')]})
    },
    dragon_girl: function () {
        return new Howl({src: [require('./src/cards/cards_dragon_girl/cards_dragon_girl_1.mp3')]})
    },
    earth_elemental: function () {
        return new Howl({src: [require('./src/cards/cards_earth_elemental/cards_earth_elemental_1.mp3')]})
    },
    fairy: function () {
        return new Howl({src: [require('./src/cards/cards_fairy/cards_fairy_1.mp3')]})
    },
    fire_bolt: function () {
        return new Howl({src: [require('./src/cards/cards_fire_bolt/cards_fire_bolt_1.mp3')]})
    },
    fire_elemental: function () {
        return new Howl({src: [require('./src/cards/cards_fire_elemental/cards_fire_elemental_1.mp3')]})
    },
    gabriela: function () {
        return new Howl({src: [require('./src/cards/cards_gabriela/cards_gabriela_1.mp3')]})
    },
    healing_potion: function () {
        return new Howl({src: [require('./src/cards/cards_healing_potion/cards_healing_potion_1.wav')]})
    },
    henchman: function () {
        return new Howl({src: [require('./src/cards/cards_henchman/cards_henchman_1.mp3')]})
    },
    horsewoman: function () {
        return new Howl({src: [require('./src/cards/cards_horsewoman/cards_horsewoman_1.mp3')]})
    },
    hurricane: function () {
        return new Howl({src: [require('./src/cards/cards_hurricane/cards_hurricane_1.mp3')]})
    },
    knighthood: function () {
        return new Howl({src: [require('./src/cards/cards_knighthood/cards_knighthood_1.mp3')]})
    },
    lightning: function () {
        return new Howl({src: [require('./src/cards/cards_lightning/cards_lightning_1.mp3')]})
    },
    mad: function () {
        return new Howl({src: [require('./src/cards/cards_mad/cards_mad_1.mp3')]})
    },
    slave: function () {
        return new Howl({src: [require('./src/cards/cards_slave/cards_slave_1.mp3')]})
    },
    snake: function () {
        return new Howl({src: [require('./src/cards/cards_snake/cards_snake_1.mp3')]})
    },
    spikes_love: function () {
        return new Howl({src: [require('./src/cards/cards_spikes_love/cards_spikes_love_1.mp3')]})
    },
    victim: function () {
        return new Howl({src: [require('./src/cards/cards_victim/cards_victim_1.wav')]})
    },
    wall_of_fire: function () {
        return new Howl({src: [require('./src/cards/cards_wall_of_fire/cards_wall_of_fire_1.mp3')]})
    },
    zombie_wizard: function () {
        return new Howl({src: [require('./src/cards/cards_zombie_wizard/cards_zombie_wizard_1.mp3')]})
    },
    sword: function () {
        return new Howl({src: [require('./src/cards/cards_sword/cards_sword_1.mp3')]})
    },
    shield: function () {
        return new Howl({src: [require('./src/cards/cards_shield/cards_shield_1.mp3')]})
    },
    troll: function () {
        return new Howl({src: [require('./src/cards/cards_sexy_troll/cards_sexy_troll_1.mp3')]})
    },


    thief: function () {
        return new Howl({src: [require('./src/cards/cards_thief/cards_thief_1.mp3')]})
    },
    kailis: function () {
        return new Howl({src: [require('./src/cards/cards_kailis/cards_kailis_1.mp3')]})
    },
    robbery: function () {
        return new Howl({src: [require('./src/cards/cards_looting/cards_looting_1.mp3')]})
    },
    riot: function () {
        return new Howl({src: [require('./src/cards/cards_riot/cards_riot_1.mp3')]})
    },

    chimera: function () {
        return new Howl({src: [require('./src/cards/cards_Chimera/cards_Chimera_1.mp3')]})
    },
    lamia: function () {
        return new Howl({src: [require('./src/cards/cards_lamia/cards_lamia_1.mp3')]})
    },
    kailis_heretic: function () {
        return new Howl({src: [require('./src/cards/cards_Kailis_Heretic/cards_Kailis_Heretic_1.mp3')]})
    },
    amulet_of_death: function () {
        return new Howl({src: [require('./src/cards/cards_Amulet_of_death/cards_Magic_Amulet_1.mp3')]})
    },
    wrath_old_god: function () {
        return new Howl({src: [require('./src/cards/cards_Wrath_old_god/cards_Doomsday_1.mp3')]})
    },
    //cards end


    // shop
    open_box: function () {
        return new Howl({src: sounds.getRandomOpenBoxSound()})
    },

    // midlleware
    healing: function () {
        return new Howl({src: [require('./src/middleware/middleware_ability_healing/middleware_ability_healing_1.mp3')]})
    },
    dark_damage: function () {
        return new Howl({src: [require('./src/middleware/middleware_ability_dark_damage/middleware_ability_dark_damage_1.mp3')]})
    },
    spark_summon: function () {
        return new Howl({src: [require('./src/middleware/middleware_ability_spark/cards_spark_1.mp3')]})
    },
    shock_damage: function () {
        return new Howl({src: [require('./src/middleware/middleware_ability_shock/middleware_ability_shock_1.mp3')]})
    },
    // midlleware end


    getMenuRandomMusic: function () {
        let src = [require('./src/menu_theme/menu_theme_1.mp3'), require('./src/menu_theme/menu_theme_2.mp3'), require('./src/menu_theme/menu_theme_3.mp3')];
        return src[Math.floor(Math.random() * 3)];
    },

    getBattleRandomMusic: function () {
        let src = [require('./src/battle_theme/battle_theme_1.mp3'), require('./src/battle_theme/battle_theme_2.mp3'), require('./src/battle_theme/battle_theme_3.mp3'), require('./src/battle_theme/battle_theme_4.mp3')];
        return src[Math.floor(Math.random() * 3)];
    },

    getTutorialRandomMusic: function () {
        let src = [require('./src/tutorial_theme/tutorial_theme_1.mp3'), require('./src/tutorial_theme/tutorial_theme_2.mp3')];
        return src[Math.floor(Math.random() * 2)];
    },

    getZombieRandomSound: function () {
        let src = [require('./src/cards/Zombie/1045_1.mp3'), require('./src/cards/Zombie/1045_2.mp3'), require('./src/cards/Zombie/1045_3.mp3'), require('./src/cards/Zombie/1045_4.mp3')];
        return src[Math.floor(Math.random() * 4)];
    },

    getPortRatsRandomSound: function () {
        let src = [require('./src/cards/cards_port_rats/cards_port_rats_1.mp3'), require('./src/cards/cards_port_rats/cards_port_rats_2.mp3'), require('./src/cards/cards_port_rats/cards_port_rats_3.mp3'), require('./src/cards/cards_port_rats/cards_port_rats_4.mp3'), require('./src/cards/cards_port_rats/cards_port_rats_5.mp3'), require('./src/cards/cards_port_rats/cards_port_rats_6.mp3')];
        return src[Math.floor(Math.random() * 4)];
    },

    getRandomOpenBoxSound: function () {
        let src = [require('./src/shop/1.wav'), require('./src/shop/2.wav'), require('./src/shop/3.wav'), require('./src/shop/4.wav')];
        return src[Math.floor(Math.random() * 4)];
    }

};