import {GabbyStory} from './gabby/GabbyStory'
import {KailisStory} from "./kailis/KailisStory";
import {ElfStory} from "./elf/ElfStory";
import { KailisStoryPart2 } from './kailis/KailisStoryPart2';
import {TowerStory} from "./tower/TowerStory";
import {TowerStoryPart2} from "./tower/TowerStoryPart2";
import {PromoStory} from "./PromoStory";

export default {
    PromoStory,
    GabbyStory,
    KailisStory,
    ElfStory,
    KailisStoryPart2,
    TowerStory,
    TowerStoryPart2
}
