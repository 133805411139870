import 'animate.css';
import './App.css';
import AppRouter from "./router/AppRouter";
import {isMobile} from 'react-device-detect';

function App() {

    let coreClass = "App";

    if (isMobile) {
        coreClass = 'AppMobile';
    }

    return (
        <div className={coreClass}>

            <AppRouter/>

        </div>
    );
}

export default App;
