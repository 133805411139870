
import { Advices } from './Advices';
import './DefaultLanding.css'

// @ts-ignore
const LoadingPopup = ({visible}) => {

    const rootClasses = ['loading'];
    if (visible) {
        rootClasses.push('loading-active login-frame-container');
    }

    return (
        <div className={rootClasses.join(' ')}>
         <div className='login-frame'>
         <div className="login-frame-text">
           <h1 className='login-frame-logo'>Depraved world</h1>
           <br />
           <p>{Advices.get()}</p>
           <div className='progress3'></div>
           <p>Loading...</p>
        </div>
          
         </div>

        </div>
    );
};

export default LoadingPopup;