import {GameActionCreators} from "../../../store/reducers/game/action-creators";
import { SoundsService } from "../../SoundsService";
import ServerStateDispatcher from "../ServerStateDispatcher";


export default class CpuPlayedCard{
    static async show(ServerState, dispatch, mass = false){

        dispatch(GameActionCreators.setCpuPlayedCard({
           card: ServerState.card
        }));

        await ServerStateDispatcher.dispatchState(ServerState.result, dispatch);

        SoundsService.playCard(ServerState.card);

        let timeout = 2500;
        if(mass === true){
            timeout = 1000;
        }
        await new Promise(r => setTimeout(r, timeout));

        dispatch(GameActionCreators.setCpuPlayedCard({
            card: null
        }));
    }
}